export const API_BASE = '/api/v1'
export const IS_PROD = window.location.hostname === 'wallet.hello.coop'
export const HELLO_APPS = ['hello_console', 'hello_quick_start']
export const FALLBACK_APP_ICON_DARK =
    'https://cdn.hello.coop/images/fallback-app-icon.png'
export const FALLBACK_APP_ICON_LIGHT =
    'https://cdn.hello.coop/images/fallback-app-icon-light.png'
export const NAMES_MAP = {
    name: 'Full Name',
    nickname: 'Preferred Name',
    given_name: 'First Name',
    family_name: 'Last Name',
}
export const DISCO_DEBOUNCE_MS = 650

// Session
export const SESSION_EXPIRE_MS = 240 * 1000 // 4min - Counts down to SESSION_EXPIRE_SHOW_MODAL_MS
export const SESSION_EXPIRE_SHOW_MODAL_MS = 30 * 1000 // 30sec
export const SESSION_MODAL_WARNING_MS = 5 * 1000 // 5 sec
export const CLIENT_REQUEST_TIMEOUT_MS = 3 * 60 * 1000 // 3min

// Managed
export const DEFAULT_MANAGED_LOGO =
    'https://cdn.hello.coop/images/default-org.png'
export const DEFAULT_MANAGED_LOGO_NAME = 'default-org.png'
export const DEFAULT_PERSONAL_LOGO =
    'https://cdn.hello.coop/images/default-personal.svg'
export const RECOMMENDED_MANAGED_LOGO_SIZE = 72

// Animations
export const FLIP_ANIM_MS = 650
export const FADE_ANIM_DELAY_MS = 50
export const SLIDE_ANIM_MS = 250

// Plausible Funnel
//New User Release Funnel (In order)
export const AUTHORIZE_FUNNEL_STEPS = [
    'az_request',
    'az_login_start',
    'az_login_success',
    'az_release',
]
//Email Upgrade Funnel (In order)
//When user types in an email -> we prompt to log in with their associated social account
export const EMAIL_UPGRADE_FUNNEL_STEPS = [
    'email_upgrade_prompt',
    'email_upgrade_start',
    'email_upgrade_success',
]
//Wizard Funnel (In order)
export const WIZARD_FUNNEL = [
    'wiz_welcome',
    'wiz_status',
    'wiz_recovery',
    'wiz_recovery_1_start',
    'wiz_recovery_1_success',
    'wiz_recovery_2_start',
    'wiz_recovery_2_success',
    'wiz_complete',
]
//Wizard Upgrade Funnel (In order)
//Prompted to upgrade after adding a social recovery in wallet wizard
export const WIZARD_UPGRADE_FUNNEL = [
    'wiz_upgrade_prompt',
    'wiz_upgrade_success',
]
